<template>
  <div>
      <!-- main page -->
      <div class="vx-row mb-12">
          <div v-bind:class="[detail ? detailShow : '', detailHide]">
              <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/3 w-full">
                      <vs-dropdown vs-trigger-click class="cursor-pointer">
                          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                              <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                          </div>
                          <vs-dropdown-menu>                
                              <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                  <span>{{item}}</span>
                              </vs-dropdown-item>
                          </vs-dropdown-menu>
                      </vs-dropdown>
                  </div>
                  <div class="mx-auto"></div>
                  <div class="vx-col sm:w-1/3 w-full">
                      <div class="flex flex-wrap items-center justify-between">
                          <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search" />
                      </div>
                  </div>
              </div>

              <vs-table :data="dataSalesOrder" stripe border>
                  <template slot="thead">
                      <vs-th>
                          <div class="vx-row mb-12">Sales Order</div>
                      </vs-th>
                      <vs-th>
                          <div class="vx-row mb-12">Territory</div>
                      </vs-th>
                      <vs-th>
                          <div class="vx-row mb-12">Customer</div>
                      </vs-th>
                  </template>

                  <template slot-scope="{data}">
                      <vs-tr :key="indexSO" v-for="(dt, indexSO) in data">
                          <vs-td>
                              <vs-button size="[detail ? 'small' : '', '']" v-on:click="clickSOCode(dt)" color="primary" type="gradient">
                                  {{dt.Code}}
                              </vs-button>
                              <br><br>
                              Customer Return Reference Code : {{dt.SalesReturn.SrReferenceCode}} <br>
                              Customer Return Code : {{dt.SalesReturn.Code}} <br>
                              Return Date : {{dateFormat(dt.SalesReturn.Date)}} <br>
                              Total Return Line : {{dt.SalesReturnLine.length}} <br>
                              Source : {{dt.Source}} <br>
                              Invoice Code : {{ dt.InvoiceCode  }}<br />
                              Invoice Reference Code : {{ dt.SoReferenceCode  }}<br />
                              Invoice Date : {{dateFormat(dt.Invoice.Date)}} <br>
                              GI : {{ dt.ProofOfDelivery.GiNumber  }}<br />
                              GIR : {{ dt.SalesReturn.GirNumber  }}<br />
                              Payment : {{ dt.Payment.Code  }}<br />
                              Posting Date : {{dateFormat(dt.PostingDate)}} <br>
                              Upload Date : {{dateFormat(dt.DefaultAttribute.CreatedAt)}} <br>
                              Due Date : {{dateFormat(dt.DueDate)}} <br>
                          </vs-td>
                          <vs-td>
                              Code : {{dt.Territory.Code}} <br>
                              Name : {{dt.Territory.Name}} 
                          </vs-td>
                          <vs-td>
                              Code : {{dt.Customer.Code}} <br>
                              Name : {{dt.CustomerName}} <br>
                              Address : {{dt.CustomerAddress}} <br>
                              Phone : {{dt.CustomerPhone}}
                          </vs-td>
                      </vs-tr>
                  </template>
              </vs-table>
      
              <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
          </div>

          <!-- sub-page-->
          <transition name="detail-fade">
              <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
              <vs-row>
                  <vs-col
                  style="padding-bottom: 8px"
                  vs-offset="8"
                  vs-type="flex"
                  vs-justify="rigth"
                  vs-align="rigth"
                  vs-w="4"
                  >
                  <vs-button
                      class="ml-auto"
                      size="small"
                      v-on:click="handleClose"
                      color="grey"
                      icon-pack="feather"
                      icon="icon-x-square"
                  >Close</vs-button>
                  </vs-col>
                  <vs-col
                  class="vertical-divider"
                  vs-offset="0"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="1"
                  >
                  <vs-button
                      size="small"
                      v-on:click="handleClose"
                      color="grey"
                      icon-pack="feather"
                      icon="icon-x-square"
                  ></vs-button>
                  </vs-col>
                  <!-- form component -->
                  
                  <detail
                   :selectedSO="selectedSO"
                  @close="handleClose"
                  ></detail>
              </vs-row>
              </div>
          </transition>
      </div>
  </div>
</template>


<script>
import detail from "./detail.vue";
import moment from 'moment'

export default {
  components: {
      detail
  },
  data() {
      return {
          dataSalesOrder: [],
          salesOrderCode: "",
          selectedSO: [{}],

          limits: [10, 25, 50, 100, "All"],
          limitShow: 50,
          drawData: 0,
          isActive: 1,
          modelCheck: [],
          search: "",
          detailShow: "core vx-col md:w-1/2 w-full mb-base ",
          detailHide: "core vx-col md:w-1/1 w-full mb-base ",
          detail: false,
          recordsTotal: 0,
          maxPage: 2,
          dataEnd: 0,
      };
  },
  watch: {
      search: function() {
          this.getData();
      },
  },
  mounted() {
      // 
  },
  computed: {
      currentPage: {
          get() {
              return 1;
          },
          set(page) {
              this.page(page);
          }
      }
  },
  methods: {
      dateFormat(date) {
          if (date) {
              return moment(String(date)).format('DD/MM/YYYY')
          }
      },

      scrollToTop() {
          window.scrollTo(0,0);
      },

      getSOLine () {
          const search = this.salesOrderCode;
          if (search) {
              this.selectedSO = this.dataSalesOrder.filter(so => so.Code.indexOf(search) > -1)[0] // filter + get 1st record
          }
      },

      clickSOCode(dt) {
          
          this.handleClose();
          setTimeout(function() {}, 3000);
          this.salesOrderCode = dt.Code;
          this.getSOLine();
          this.detail = true;
          this.scrollToTop();
      },

      handleClose() {
          this.getData();
          this.detail = false;
      },

      page(page) {
          this.getData(page);
          return page;
      },

      changeLimit(limit) {
          this.limitShow = limit;
          this.getData(this.isActive);
      },

      getData(page = 1) {
          this.isActive = page;
          this.$vs.loading();

          this.$http.get("/api/v1/sales-return-management/9", {
              params: {
                  //approval_manager: 1,
                  limit: this.limitShow,
                  search: this.search,
                  draw: this.drawData,
                  offset: this.limitShow * (page - 1),
                  start: this.limitShow * (page - 1)
              }
          }).then(resp => {
              // console.log(resp.data);
              if (!resp.data) {
                  // error    
                  this.$vs.notify({
                      title: "Error",
                      text: resp.message,
                      color: "danger",
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-check"
                  });                
                  this.$vs.loading.close();
              } else {
                  this.drawData = this.drawData + 1;
                  this.dataSalesOrder = resp.data.SalesOrders;
                  this.recordsTotal = resp.data.RecordsTotal;
                  this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                  this.dataEnd = resp.data.ResultsTotal;
                  this.checkedAll = false;
                  this.$vs.loading.close();
              }
          });
      },

      
  }
};
</script>

<style>
.core-enter-active {
transition: all 0.3s ease;
}
.core-leave-active {
transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
transform: translateX(10px);
opacity: 0;
}
.core {
-webkit-transition: all 1s ease;
-moz-transition: all 1s ease;
-o-transition: all 1s ease;
transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
font-size: 12px;
}
.vertical-divider {
border-left: 1px solid #7367f0;
min-height: 800px;
padding: 5px;
}
</style>
<template>
    <div class="vx-col w-5/6 ml-auto mr-auto">
        <div class="vx-row mb-12" style="width:100%">
            <div class="vx-col sm:w-1/3 w-full">
				<span>Customer Return Code</span>
			</div>
            <div class="vx-col sm:w-3/3 w-full">
                <vs-input class="w-full" v-model="salesReturn.Code" disabled />
            </div>
            <br><br><br>
			<div class="vx-col sm:w-1/3 w-full">
				<span>Sales Order Code</span>
			</div>
            <div class="vx-col sm:w-3/3 w-full">
                <vs-input class="w-full" v-model="salesOrder.Code" disabled />
            </div>
            <br><br><br>
            <div class="vx-col sm:w-1/3 w-full">
				<span>Customer Return Type</span>
			</div>
            <div class="vx-col sm:w-3/3 w-full">
                <multiselect
                v-model="salesReturnType.selected"
                :options="salesReturnType.options"
                track-by="name"
                label="name"
                :max-height="125"
                :show-labels="false"
                v-validate="'required'"
                disabled
                >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                    <span class="option__title"
                        >{{ props.option.Name }}</span
                    >
                    </span>
                </template>

                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                    <span class="option__title"
                        >{{ props.option.Name }}</span
                    >
                    </div>
                </template>
                </multiselect>
            </div>

            <br><br><br>
            <div class="vx-col sm:w-1/3 w-full">
				<span>Note</span>
			</div>
            <div class="vx-col sm:w-3/3 w-full">
                <vs-textarea class="w-full" v-model="data.note" v-validate="'required'" disabled />
            </div>
        </div>

        <div class="vx-row mb-6" style="width:100%">
            <div class="vx-col sm:w-1/3 w-full">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">
                            {{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}
                        </span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                            <span>{{item}}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>
            <div class="mx-auto"></div>
                <div class="vx-col sm:w-1/3 w-full">
                    <div class="flex flex-wrap items-center justify-between">
                        <vs-input class="mb-4 md:mb-0 mr-4" v-model="search" placeholder="Search..." />
                    </div>
                </div>
            </div>

            <!-- TABLE ACTION ROW -->
            <vs-table :data="data" stripe style="width:100%">
                <template slot="thead">
                    <vs-th>Name</vs-th>
                    <vs-th>Qty Return</vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td style="align-item-center">{{data[indextr].ItemName}}</vs-td>
                        <vs-td>{{data[indextr].Quantity}} ({{data[indextr].ItemUnit}})</vs-td>
                    </vs-tr>
                </template>
            </vs-table>

        <!-- PAGINATE -->
        <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPageDetail" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
    </div>
</template>
<script>
export default {
    props: ["selectedSO"],
    created(){
        // console.log("created")
    },
    mounted(){
        this.salesOrder = this.selectedSO;
        this.data = this.salesOrder.SalesReturnLine;
        this.salesReturn = this.salesOrder.SalesReturn;
        this.loadDatatable(1);
        this.data.note = this.salesOrder.SalesReturn.Note
        this.getAllDataSalesReturnType();
        if (this.salesOrder.SalesReturn.SalesReturnTypeID) {
            this.setSalesReturnTypeSelected(this.salesOrder.SalesReturn.SalesReturnTypeID);
        }
    },
    watch: {
        selectedSO() {
            this.salesOrder = this.selectedSO;
            this.data = this.salesOrder.SalesReturnLine;
            this.salesReturn = this.salesOrder.SalesReturn;
            this.loadDatatable(1);
            this.data.note = this.salesOrder.SalesReturn.Note
        },
        search: function() {       
            this.data = this.salesOrder.SalesReturnLine.filter(tr => tr.ItemName.toLowerCase().includes(this.search.toLowerCase()))
        },
        "salesReturnType.selected": function (val) {
            if (val) {
                this.errors.clear();
                this.data.salesReturnTypeID = val.id ? val.id : val.ID;
            } else {
                this.data.salesReturnTypeID = null;
            }
        },
    },
    computed: {
        currentPageDetail: {
            get() {
                return 1;
            },
            set(val) {
                this.pageDetail(val);
            }
        }
    },
    data: () => ({
        limits: [5,10, 25, 50],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        checked: [],
        modelCheck: [],
        checkedAll: false,
        search: "",
        detailShow: "core vx-col md:w-1/2 w-full mb-base",
        detailHide: "core vx-col md:w-1/1 w-full mb-base",
        detail: false,
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
        data: [{}],
        salesOrder: {},
        salesReturn: {},
        salesReturnTypeID: null,     
        salesReturnType: {
          selected: null,
          options: [],
        },
        note: "",
    }),
    methods: {
        pageDetail(val) {
            this.loadDatatable(val);
            return val;
        },
        changeLimit(val) {
            this.limitShow = val;
            this.loadDatatable(this.isActive);
        },
        loadDatatable(val=1) {
            this.isActive = val;

            this.drawData = this.drawData + 1;
            this.recordsTotal = this.data.length;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = this.data.length;
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        
        getAllDataSalesReturnType() {
            this.$vs.loading();
            this.$http
                .get("/api/v1/sales-return-type", {
                params: {
                    order: "name",
                    sort: "asc",
                },
                })
                .then((resp) => {
                if (resp.status == "success") {
                    this.salesReturnType.options = resp.data.records;
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
                });
        },
        setSalesReturnTypeSelected(id) {
            this.$vs.loading();
            this.$http.get("/api/v1/sales-return-type/" + id).then((resp) => {
                if (resp.status == "success") {
                this.salesReturnType.selected = resp.data;
                this.$vs.loading.close();
                } else {
                this.$vs.loading.close();
                }
            });
        },
    }
}

</script>
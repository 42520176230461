<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="CR With SO Reference">
            <vs-tabs :color="colorx">
                <vs-tab v-if="this.$userLogin.roles[0].Name != 'territory_manager'" @click="colorx = 'success'" label="Sales Order">
                    <div class="con-tab-ejemplo">
                        <open-retur></open-retur>
                    </div>
                </vs-tab>
                <vs-tab v-if="this.$userLogin.roles[0].Name == 'territory_manager'" @click="colorx = 'success'" label="Approval">
                    <approval-retur></approval-retur>
                </vs-tab>
                <vs-tab v-if="this.$userLogin.roles[0].Name != 'territory_manager'" @click="colorx = 'success'" label="Customer Return">
                    <approval-retur></approval-retur>
                </vs-tab>
                
                <vs-tab v-if="this.$userLogin.roles[0].Name == 'territory_manager'" @click="colorx = 'success'" label="Approved">
                    <release-retur></release-retur>
                </vs-tab>

                <vs-tab @click="colorx = 'success'" label="Rejected">
                    <reject-retur></reject-retur>
                </vs-tab>
                <vs-tab v-if="this.$userLogin.roles[0].Name != 'territory_manager'" @click="colorx = 'success'" label="Release">
                    <release-retur></release-retur>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Released">
                    <released-retur></released-retur>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Complete">
                    <complete-retur></complete-retur>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Reversed">
                    <reversed-retur></reversed-retur>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import openRetur from "./open/datatable.vue";
import approvalRetur from "./approval/datatable.vue";
import releaseRetur from "./release/datatable.vue";
import rejectRetur from "./reject/datatable.vue";
import completeRetur from "./complete/datatable.vue";
import releasedRetur from "./released/datatable.vue";
import reversedRetur from "./reversed/datatable.vue";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        'open-retur': openRetur,
        'approval-retur': approvalRetur,
        'release-retur': releaseRetur,
        'reject-retur': rejectRetur,
        'complete-retur': completeRetur,
        'released-retur': releasedRetur,
        'reversed-retur': reversedRetur,

        Datepicker, 
    },
    data: () => ({
        colorx: "success",
    }),
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>